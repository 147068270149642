// Actions
const GET_USER = "user/GET-USER";
const GET_USER_SUCCESS = "user/GET-USER_SUCCESS";
const GET_USER_FAIL = "user/GET-USER_FAIL";

const CREATE_USER = "user/CREATE-USER";
const CREATE_USER_SUCCESS = "user/CREATE-USER_SUCCESS";
const CREATE_USER_FAIL = "user/CREATE-USER_FAIL";

const UPDATE_USER = "user/UPDATE-USER";
const UPDATE_USER_SUCCESS = "user/UPDATE-USER_SUCCESS";
const UPDATE_USER_FAIL = "user/UPDATE-USER_FAIL";

const DELETE_USER = "user/DELETE-USER";
const DELETE_USER_SUCCESS = "user/DELETE-USER_SUCCESS";
const DELETE_USER_FAIL = "user/DELETE-USER_FAIL";

const DETAIL_USER = "user/DETAIL-USER";
const DETAIL_USER_SUCCESS = "user/DETAIL-USER_SUCCESS";
const DETAIL_USER_FAIL = "user/DETAIL-USER_FAIL";

const initialState = {
  dataGetUser: [],
  getUserStatus: false,
  createUserStatus: false,
  createUserMessage: "",
  createUserNotif: "",
  detailUserData: {},
  detailUserStatus: false,
  updateUserStatus: false,
  updateUserMessage: "",
  deleteUserStatus: false,
  deleteUserMessage: "",
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_USER:
      return {
        ...state,
        dataGetUser: [],
        getUserStatus: false,
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        dataGetUser: action.payload.status === 200 ? action.payload.data : [],
        getUserStatus: true,
      };
    case GET_USER_FAIL:
      return state;

    ////
    case CREATE_USER:
      return {
        ...state,
        createUserStatus: false,
        createUserMessage: "",
      };
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        createUserStatus: true,
        createUserMessage:
          action.payload.status === 200 ? "Add Data User Success" : "",
      };
    case CREATE_USER_FAIL:
      return {
        ...state,
        createUserNotif: "FAIL",
        createUserStatus: false,
        createUserMessage: errorChecking(action.error),
      };

    ////
    case UPDATE_USER:
      return {
        ...state,
        updateUserStatus: false,
        updateUserMessage: "",
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        updateUserStatus: true,
        updateUserMessage: action.payload.status === 200 ? "SUCCESS" : "",
      };
    case UPDATE_USER_FAIL:
      return {
        ...state,
        updateUserStatus: false,
        updateUserMessage: "FAIL",
      };

    ////
    case DELETE_USER:
      return {
        ...state,
        deleteUserStatus: false,
        deleteUserMessage: "",
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        deleteUserStatus: true,
        deleteUserMessage: action.payload.status === 200 ? "SUCCESS" : "",
      };
    case DELETE_USER_FAIL:
      return {
        ...state,
        deleteUserStatus: false,
        deleteUserMessage: "FAIL",
      };

    /////
    case DETAIL_USER:
      return {
        ...state,
        detailUserData: {},
        detailUserStatus: false,
      };
    case DETAIL_USER_SUCCESS:
      return {
        ...state,
        detailUserData:
          action.payload.status === 200 ? action.payload.data : {},
        detailUserStatus: true,
      };
    case DETAIL_USER_FAIL:
      return {
        ...state,
        detailUserData: {},
        detailUserStatus: false,
      };
    default:
      return state;
  }
}

function errorChecking(err) {
  let message;
  console.log(err);
  if (err.data) {
    message = err.data;
  } else if (err.response) {
    message = err.response.data.errmsg;
  } else {
    message = "unknown";
  }

  return message;
}
// Action Creators
export function getUser() {
  return {
    type: GET_USER,
    payload: {
      request: {
        method: "GET",
        url:
          "https://vkpjzzf2yh.execute-api.ap-southeast-1.amazonaws.com/dev/get-a-user-by-role/?role=pic",
        headers: {},
      },
    },
  };
}

export function createUser(dataBody) {
  return {
    type: CREATE_USER,
    payload: {
      request: {
        method: "POST",
        url:
          "https://vkpjzzf2yh.execute-api.ap-southeast-1.amazonaws.com/dev/add-user",
        data: dataBody,
      },
    },
  };
}

export function updateUser(data) {
  return {
    type: UPDATE_USER,
    payload: {
      request: {
        method: "PUT",
        url:
          "https://vkpjzzf2yh.execute-api.ap-southeast-1.amazonaws.com/dev/add-update/" +
          data.id,
        data: data,
      },
    },
  };
}

export function deleteUser(data) {
  return {
    type: DELETE_USER,
    payload: {
      request: {
        method: "DELETE",
        url:
          "https://vkpjzzf2yh.execute-api.ap-southeast-1.amazonaws.com/dev/add-delete/" +
          data.id,
      },
    },
  };
}

export function detailUser(data) {
  return {
    type: DETAIL_USER,
    payload: {
      request: {
        method: "GET",
        url:
          "https://i5rh6w7jol.execute-api.ap-southeast-1.amazonaws.com/Prod/user-detail/" +
          data.id,
      },
    },
  };
}
