// Actions
const LOGIN = "auth/LOGIN";
const LOGIN_SUCCESS = "auth/LOGIN_SUCCESS";
const LOGIN_FAIL = "auth/LOGIN_FAIL";

const LOGOUT = "auth/LOGOUT";
const LOGOUT_SUCCESS = "auth/LOGOUT_SUCCESS";
const LOGOUT_FAIL = "auth/LOGOUT_FAIL";

const initialState = {
  loginStatus: false,
  loginData: {},
  outletData: [],
  loginMessage: "",
};

let status = false,
  logData;

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOGOUT:
      return initialState;
    case LOGIN:
      return {
        ...state,
        loginStatus: false,
        loginData: {},
        outletData: [],
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loginStatus: true,
        loginData: action.payload.data,
        outletData: [],
      };
    case LOGIN_FAIL:
      return {
        ...state,
        loginMessage: "FAIL",
      };
    default:
      return state;
  }
}

// Action Creators
export function loginHandler(data) {
  return {
    type: LOGIN,
    payload: {
      request: {
        method: "POST",
        url:
          "https://vkpjzzf2yh.execute-api.ap-southeast-1.amazonaws.com/dev/login",
        data: data,
      },
    },
  };
}
export function logout() {
  return {
    type: LOGOUT,
  };
}
