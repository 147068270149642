// Actions
const GET_OUTLET = "outlet/GET-OUTLET";
const GET_OUTLET_SUCCESS = "outlet/GET-OUTLET_SUCCESS";
const GET_OUTLET_FAIL = "outlet/GET-OUTLET_FAIL";

const CREATE_OUTLET = "outlet/CREATE-OUTLET";
const CREATE_OUTLET_SUCCESS = "outlet/CREATE-OUTLET_SUCCESS";
const CREATE_OUTLET_FAIL = "outlet/CREATE-OUTLET_FAIL";

const UPDATE_OUTLET = "outlet/UPDATE-OUTLET";
const UPDATE_OUTLET_SUCCESS = "outlet/UPDATE-OUTLET_SUCCESS";
const UPDATE_OUTLET_FAIL = "outlet/UPDATE-OUTLET_FAIL";

const DELETE_OUTLET = "outlet/DELETE-OUTLET";
const DELETE_OUTLET_SUCCESS = "outlet/DELETE-OUTLET_SUCCESS";
const DELETE_OUTLET_FAIL = "outlet/DELETE-OUTLET_FAIL";

const DETAIL_OUTLET = "outlet/DETAIL-OUTLET";
const DETAIL_OUTLET_SUCCESS = "outlet/DETAIL-OUTLET_SUCCESS";
const DETAIL_OUTLET_FAIL = "outlet/DETAIL-OUTLET_FAIL";

const initialState = {
  dataGetOutlet: [],
  getOutletStatus: false,
  createOutletStatus: false,
  createOutletMessage: "",
  detailOutletData: {},
  detailOutletStatus: false,
  updateOutletStatus: false,
  updateOutletMessage: "",
  deleteOutletStatus: false,
  deleteOutletMessage: "",
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_OUTLET:
      return {
        ...state,
        dataGetOutlet: [],
        getOutletStatus: false,
      };
    case GET_OUTLET_SUCCESS:
      return {
        ...state,
        dataGetOutlet: action.payload.status === 200 ? action.payload.data : [],
        getOutletStatus: true,
      };
    case GET_OUTLET_FAIL:
      return state;

    ////
    case CREATE_OUTLET:
      return {
        ...state,
        createOutletStatus: false,
        createOutletMessage: "",
      };
    case CREATE_OUTLET_SUCCESS:
      return {
        ...state,
        createOutletStatus: true,
        createOutletMessage:
          action.payload.status === 200
            ? "Add Data Outlet Success"
            : "Sorry Bro Add Data Fail ",
      };
    case CREATE_OUTLET_FAIL:
      return {
        ...state,
        createOutletStatus: false,
        createOutletMessage:
          action.error.response.status === 500 ? "Data Uncomplete Bro" : "",
      };

    ////
    case UPDATE_OUTLET:
      return {
        ...state,
        updateOutletStatus: false,
        updateOutletMessage: "",
      };
    case UPDATE_OUTLET_SUCCESS:
      return {
        ...state,
        updateOutletStatus: true,
        updateOutletMessage: "",
      };

    case UPDATE_OUTLET_FAIL:
      return {
        ...state,
      };

    ////
    case DELETE_OUTLET:
      return {
        ...state,
        deleteOutletStatus: false,
        deleteOutletMessage: "",
      };
    case DELETE_OUTLET_SUCCESS:
      return {
        ...state,
        deleteOutletStatus: true,
        deleteOutletMessage:
          action.payload.status === 200 ? "SUCCESS" : "FAIL TO UPDATE",
      };
    case DELETE_OUTLET_FAIL:
      return {
        ...state,
        deleteOutletStatus: false,
        deleteOutletMessage: "FAIL",
      };

    ////
    case DETAIL_OUTLET:
      return {
        ...state,
        detailOutletData: {},
        detailOutletStatus: false,
      };
    case DETAIL_OUTLET_SUCCESS:
      return {
        ...state,
        detailOutletData:
          action.payload.status === 200 ? action.payload.data : {},
        detailOutletStatus: true,
      };
    case DETAIL_OUTLET_FAIL:
      return {
        ...state,
      };
    default:
      return state;
  }
}

// Action Creators
export function getOutlet() {
  return {
    type: GET_OUTLET,
    payload: {
      request: {
        method: "GET",
        url:
          "https://7v2vz3ikbi.execute-api.ap-southeast-1.amazonaws.com/dev/get-outlet",
        headers: {},
      },
    },
  };
}

export function createOutlet(dataBody) {
  return {
    type: CREATE_OUTLET,
    payload: {
      request: {
        method: "POST",
        url:
          "https://7v2vz3ikbi.execute-api.ap-southeast-1.amazonaws.com/dev/add-outlet",
        data: dataBody,
      },
    },
  };
}

export function updateOutlet(data) {
  return {
    type: UPDATE_OUTLET,
    payload: {
      request: {
        method: "PUT",
        url:
          "https://bpccogdsbl.execute-api.ap-southeast-1.amazonaws.com/Prod/outlet-update/" +
          data.id,
        data: data,
      },
    },
  };
}

export function deleteOutlet(data) {
  return {
    type: DELETE_OUTLET,
    payload: {
      request: {
        method: "DELETE",
        url:
          "https://bpccogdsbl.execute-api.ap-southeast-1.amazonaws.com/Prod/outlet-delete/" +
          data.id,
      },
    },
  };
}

export function detailOutlet(data) {
  return {
    type: DETAIL_OUTLET,
    payload: {
      request: {
        method: "GET",
        url:
          "https://bpccogdsbl.execute-api.ap-southeast-1.amazonaws.com/Prod/outlet-detail/" +
          data.id,
      },
    },
  };
}
