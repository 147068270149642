import { act } from "react-test-renderer";

// Actions
const GET_SALES_BY_AREA_AND_DATE = "sales/GET-SALES-BY-AREA-AND-DATE";
const GET_SALES_BY_AREA_AND_DATE_SUCCESS =
  "sales/GET-SALES-BY-AREA-AND-DATE_SUCCESS";
const GET_SALES_BY_AREA_AND_DATE_FAIL = "sales/GET-SALES-BY-AREA-AND-DATE_FAIL";

const GET_SALES_BY_DATE = "sales/GET-SALES-BY-DATE";
const GET_SALES_BY_DATE_SUCCESS = "sales/GET-SALES-BY-DATE_SUCCESS";
const GET_SALES_BY_DATE_FAIL = "sales/GET-SALES-BY-DATE_FAIL";

const GET_SALES_BY_PIC_OUTLET_DAILY = "sales/GET-SALES-BY-PIC-OUTLET-DAILY";
const GET_SALES_BY_PIC_OUTLET_DAILY_SUCCESS =
  "sales/GET-SALES-BY-PIC-OUTLET-DAILY_SUCCESS";
const GET_SALES_BY_PIC_OUTLET_DAILY_FAIL =
  "sales/GET-SALES-BY-PIC-OUTLET-DAILY_FAIL";

const GET_SALES_BY_PIC_OUTLET = "sales/GET-SALES-BY-PIC-OUTLET";
const GET_SALES_BY_PIC_OUTLET_SUCCESS = "sales/GET-SALES-BY-PIC-OUTLET_SUCCESS";
const GET_SALES_BY_PIC_OUTLET_FAIL = "sales/GET-SALES-BY-PIC-OUTLET_FAIL";

const GET_SALES_DAILY = "sales/GET-SALES-DAILY";
const GET_SALES_DAILY_SUCCESS = "sales/GET-SALES-DAILY_SUCCESS";
const GET_SALES_DAILY_FAIL = "sales/GET-SALES-DAILY_FAIL";

const GET_SALES_MONTHLY = "sales/GET-SALES-MONTHLY";
const GET_SALES_MONTHLY_SUCCESS = "sales/GET-SALES-MONTHLY_SUCCESS";
const GET_SALES_MONTHLY_FAIL = "sales/GET-SALES-MONTHLY_FAIL";

const GET_SALES_BY_PIC = "sales/GET-SALES-BY-PIC";
const GET_SALES_BY_PIC_SUCCESS = "sales/GET-SALES-BY-PIC_SUCCESS";
const GET_SALES_BY_PIC_FAIL = "sales/GET-SALES-BY-PIC_FAIL";

const GET_SALES = "sales/GET-SALES";
const GET_SALES_SUCCESS = "sales/GET-SALES_SUCCESS";
const GET_SALES_FAIL = "sales/GET-SALES_FAIL";

const CREATE_SALES = "sales/CREATE-SALES";
const CREATE_SALES_SUCCESS = "sales/CREATE-SALES_SUCCESS";
const CREATE_SALES_FAIL = "sales/CREATE-SALES_FAIL";

const UPDATE_SALES = "sales/UPDATE-SALES";
const UPDATE_SALES_SUCCESS = "sales/UPDATE-SALES_SUCCESS";
const UPDATE_SALES_FAIL = "sales/UPDATE-SALES_FAIL";

const DELETE_SALES = "sales/DELETE-SALES";
const DELETE_SALES_SUCCESS = "sales/DELETE-SALES_SUCCESS";
const DELETE_SALES_FAIL = "sales/DELETE-SALES_FAIL";

const DETAIL_SALES = "sales/DETAIL-SALES";
const DETAIL_SALES_SUCCESS = "sales/DETAIL-SALES_SUCCESS";
const DETAIL_SALES_FAIL = "sales/DETAIL-SALES_FAIL";

const initialState = {
  getDataSales: [],
  getDataStatus: false,
  createSalesMessage: "",
  createSalesStatus: false,
  getSalesDaily: [],
  getSalesDailyStatus: false,
  getSalesMonthly: [],
  getSalesMonthlyStatus: false,
  getDetailData: {},
  getDetailStatus: false,
  updateSalesStatus: false,
  updateSalesMessage: "",
  deleteSalesStatus: false,
  deleteSalesMessage: "",
  getSalesByPicOutletData: [],
  getSalesByPicOutletStatus: false,
  getSalesByPicOutletDailyData: [],
  getSalesByPicOutletDailyStatus: false,
  getSalesByDateData: [],
  getSalesByDateStatus: false,
  getSalesByDateMessage: "",
  getSalesByAreaAndDateData: [],
  getSalesByAreaAndDateStatus: false,
  getSalesByAreaAndDateMessage: "",
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_SALES_BY_AREA_AND_DATE:
      return {
        ...state,
        getSalesByAreaAndDateData: [],
        getSalesByAreaAndDateStatus: false,
        getSalesByAreaAndDateMessage: "",
      };
    case GET_SALES_BY_AREA_AND_DATE_SUCCESS:
      return {
        ...state,
        getSalesByAreaAndDateData:
          action.payload.status === 200 ? action.payload.data : [],
        getSalesByAreaAndDateStatus: true,
        getSalesByAreaAndDateMessage: "SUCCESS",
      };
    case GET_SALES_BY_AREA_AND_DATE_FAIL:
      return {
        ...state,
        getSalesByAreaAndDateData: [],
        getSalesByAreaAndDateStatus: false,
        getSalesByAreaAndDateMessage: "FAIL",
      };

    /////
    case GET_SALES_BY_DATE:
      return {
        ...state,
        getSalesByDateData: [],
        getSalesByDateStatus: false,
      };
    case GET_SALES_BY_DATE_SUCCESS:
      return {
        ...state,
        getSalesByDateData:
          action.payload.status === 200 ? action.payload.data : [],
        totalSalesByDate: calculateTotalSalesArray(action.payload.data),
        totalBoxByDate: calculateTotalBoxArray(action.payload.data),
        totalTransactionByDate: calculateTotalTransactionArray(
          action.payload.data
        ),
        totalDeliveryByDate: calculateTotalDeliveryArray(action.payload.data),
        totalSummaryByDateFromNumberOfOutlet: action.payload.data.length,
        getSalesByDateMessage: "SUCCESS",
        getSalesByDateStatus: true,
      };
    case GET_SALES_BY_DATE_FAIL:
      return {
        ...state,
        getSalesByDateData: [],
        getSalesByDateStatus: false,
        getSalesByDateMessage: "FAIL",
      };

    ////
    case GET_SALES_BY_PIC_OUTLET_DAILY:
      return {
        ...state,
        getSalesByPicOutletDailyData: [],
        getSalesByPicOutletDailyStatus: false,
      };
    case GET_SALES_BY_PIC_OUTLET_DAILY_SUCCESS:
      return {
        ...state,
        getSalesByPicOutletDailyData:
          action.payload.status === 200 ? action.payload.data : [],
        getSalesByPicOutletDailyStatus: true,
      };
    case GET_SALES_BY_PIC_OUTLET_DAILY_FAIL:
      return {
        ...state,
        getSalesByPicOutletDailyData: [],
        getSalesByPicOutletDailyStatus: false,
      };

    ////
    case GET_SALES_BY_PIC_OUTLET:
      return {
        ...state,
        getSalesByPicOutletData: [],
        getSalesByPicOutletStatus: false,
      };
    case GET_SALES_BY_PIC_OUTLET_SUCCESS:
      return {
        ...state,
        getSalesByPicOutletData:
          action.payload.status === 200 ? action.payload.data : [],
        getSalesByPicOutletStatus: true,
      };
    case GET_SALES_BY_PIC_OUTLET_FAIL:
      return {
        ...state,
        getSalesByPicOutletData: [],
        getSalesByPicOutletStatus: false,
      };

    ////
    case GET_SALES_DAILY:
      return {
        ...state,
        getSalesDaily: [],
        getSalesDailyStatus: false,
      };
    case GET_SALES_DAILY_SUCCESS:
      return {
        ...state,
        getSalesDaily: action.payload.data,
        totalSalesDaily: calculateTotalSalesArray(action.payload.data),
        totalBoxDaily: calculateTotalBoxArray(action.payload.data),
        totalTransactionDaily: calculateTotalTransactionArray(
          action.payload.data
        ),
        totalDeliveryDaily: calculateTotalDeliveryArray(action.payload.data),
        totalSummaryDailyFromNumberOfOutlet: action.payload.data.length,
        getSalesDailyStatus: true,
      };
    case GET_SALES_DAILY_FAIL:
      return state;

    ////
    case GET_SALES_MONTHLY:
      return {
        ...state,
        getSalesMonthly: [],
        getSalesMonthlyStatus: false,
      };

    case GET_SALES_MONTHLY_SUCCESS:
      return {
        ...state,
        getSalesMonthly:
          action.payload.status === 200 ? action.payload.data : [],
        totalSalesMonthly: calculateTotalSalesArray(action.payload.data),
        totalBoxMonthly: calculateTotalBoxArray(action.payload.data),
        totalTransactionMonthly: calculateTotalTransactionArray(
          action.payload.data
        ),
        totalDeliveryMonthly: calculateTotalDeliveryArray(action.payload.data),
        totalSummaryMonthlyFromNumberOfOutlet: action.payload.data.length,
        getSalesMonthlyStatus: true,
      };

    case GET_SALES_MONTHLY_FAIL:
      return state;

    ////
    case GET_SALES_BY_PIC:
      return {
        ...state,
        getSalesByPic: [],
        getSalesByPicStatus: false,
      };

    case GET_SALES_BY_PIC_SUCCESS:
      return {
        ...state,
        getSalesByPic: [],
        getSalesByPicStatus: false,
      };

    case GET_SALES_BY_PIC_FAIL:
      return state;

    ////
    case GET_SALES:
      return {
        ...state,
        getDataSales: [],
        getDataStatus: false,
      };
    case GET_SALES_SUCCESS:
      return {
        ...state,
        getDataSales: action.payload.status === 200 ? action.payload.data : [],
        getDataStatus: true,
      };
    case GET_SALES_FAIL:
      return {
        ...state,
      };

    /////
    case CREATE_SALES:
      return {
        ...state,
        createSalesMessage: "On Proses insert data",
        createSalesStatus: false,
      };
    case CREATE_SALES_SUCCESS:
      return {
        ...state,
        createSalesMessage:
          action.payload.status === 200
            ? "Insert Data Success"
            : "Something Wrong",
        createSalesStatus: true,
      };
    case CREATE_SALES_FAIL:
      return {
        ...state,
        createSalesMessage:
          action.error.response.status === 400 ? "Data Existing" : "FAIL",
      };

    ////
    case UPDATE_SALES:
      return {
        ...state,
        updateSalesStatus: false,
        updateSalesMessage: "",
      };
    case UPDATE_SALES_SUCCESS:
      return {
        ...state,
        updateSalesStatus: true,
        updateSalesMessage: action.payload.status === 200 ? "SUCCESS" : "FAIL",
      };
    case UPDATE_SALES_FAIL:
      return {
        ...state,
        updateSalesStatus: false,
        updateSalesMessage: "FAIL",
      };

    ////
    case DELETE_SALES:
      return {
        ...state,
        deleteSalesStatus: false,
        deleteSalesMessage: "",
      };
    case DELETE_SALES_SUCCESS:
      return {
        ...state,
        deleteSalesStatus: true,
        deleteSalesMessage: action.payload.status === 200 ? "SUCCESS" : "FAIL",
      };
    case DELETE_SALES_FAIL:
      return {
        ...state,
        deleteSalesStatus: false,
        deleteSalesMessage: "FAIL",
      };

    ////
    case DETAIL_SALES:
      return {
        ...state,
        getDetailData: {},
        getDetailStatus: false,
      };
    case DETAIL_SALES_SUCCESS:
      return {
        ...state,
        getDetailData: action.payload.status === 200 ? action.payload.data : {},
        getDetailStatus: true,
      };
    case DETAIL_SALES_FAIL:
      return state;

    ////
    default:
      return state;
  }
}

// dineinSales: 100000
// quantityRiceBoxDinein: 9
// quantityDrinks: 9
// quantitySnacks: 9
// quantityDailysuki: 9
// quantityPack: 9
// quantityDineinTransaction: 9
// goFoodSales: 300000
// quantityRiceBoxGoFood: 9
// quantityGoPay: 9
// quantityCashGoFood: 9
// othersGoFood: 9
// quantityGoFoodTransaction: 9
// grabFoodSales: 400000
// quantityRiceBoxGrabFood: 6
// quantityOvo: 6
// quantityCashGrabFood: 6
// othersGrabFood: 6
// quantityGrabFoodTransaction: 6
// dailymealSales: 30000
// quantityRiceBoxDailymeals: 1
// quantityZomatoGold: 6
// debitBcaEdc: 20000
// kreditBcaEdc: 0
// ovoBarcode: 50000
// gopayBarcode: 500000
// cash: 100000
// transfer: 0
// traveloka: 0
// shopeePay: 0
// otherPayment: 0
// goOrder: 0
// discKaryawan: 0
// quantityDiscKaryawan: 0
// discDaily: 0
// quantityDiscDaily: 0
// discOthers: 0
// quantityDiscOthers: 0
// notes: "qwertyuiohdbshdb"
// createdAt: "2020-04-08T18:32:49.361Z"
// updatedAt: "2020-04-08T18:32:49.361Z"
// __v: 0

function calculateTotalSalesArray(data) {
  let hasil = data.reduce((a, b) => a + calculateTotalSales(b), 0);
  return hasil;
}

function calculateTotalSales(data) {
  let total =
    data.dineinSales +
    data.goFoodSales +
    data.grabFoodSales +
    data.dailymealSales +
    data.foc -
    data.foc;
  return total;
}

function calculateTotalBoxArray(data) {
  let hasil = data.reduce((a, b) => a + calculateTotalBox(b), 0);
  return hasil;
}

function calculateTotalBox(data) {
  let total =
    data.quantityRiceBoxDinein +
    data.quantityRiceBoxGoFood +
    data.quantityRiceBoxGrabFood +
    data.quantityRiceBoxDailymeals;
  return total;
}

function calculateTotalTransactionArray(data) {
  let hasil = data.reduce((a, b) => a + calculateTotalTransaction(b), 0);
  return hasil;
}

function calculateTotalTransaction(data) {
  let total =
    data.quantityDineinTransaction +
    data.quantityGoFoodTransaction +
    data.quantityGrabFoodTransaction;
  return total;
}
function calculateTotalDeliveryArray(data) {
  let hasil = data.reduce((a, b) => a + calculateTotalDelivery(b), 0);
  return hasil;
}
function calculateTotalDelivery(data) {
  let total = data.goFoodSales + data.grabFoodSales;
  return total;
}

// Action Creators
export function getSalesByAreaAndDate(data) {
  return {
    type: GET_SALES_BY_AREA_AND_DATE,
    payload: {
      request: {
        url:
          "https://n5t4bjbf28.execute-api.ap-southeast-1.amazonaws.com/Prod/sales-by-Date/area/?outlet=" +
          "[" +
          data.array +
          "]" +
          "&startDate=" +
          data.startdate +
          "&endDate=" +
          data.enddate,
        method: "GET",
      },
    },
  };
}

export function getSalesByDate(data) {
  return {
    type: GET_SALES_BY_DATE,
    payload: {
      request: {
        url:
          "https://n5t4bjbf28.execute-api.ap-southeast-1.amazonaws.com/Prod/sales-by-Date/?startDate=" +
          data.startdate +
          "&endDate=" +
          data.enddate,
        method: "GET",
      },
    },
  };
}

export function getSalesByPicOutletDaily(data) {
  const array = [data];
  return {
    type: GET_SALES_BY_PIC_OUTLET_DAILY,
    payload: {
      request: {
        url:
          "https://n5t4bjbf28.execute-api.ap-southeast-1.amazonaws.com/Prod/sales-by-outlet/daily/?outlet=" +
          "[" +
          array +
          "]",
        method: "GET",
      },
    },
  };
}

export function getSalesByPicOutlet(data) {
  const array = [data];
  return {
    type: GET_SALES_BY_PIC_OUTLET,
    payload: {
      request: {
        url:
          "https://n5t4bjbf28.execute-api.ap-southeast-1.amazonaws.com/Prod/sales-by-outlet/monthly/?outlet=" +
          "[" +
          array +
          "]",
        method: "GET",
      },
    },
  };
}

export function getSalesDaily() {
  return {
    type: GET_SALES_DAILY,
    payload: {
      request: {
        method: "GET",
        url:
          "https://n5t4bjbf28.execute-api.ap-southeast-1.amazonaws.com/Prod/sales-today",
      },
    },
  };
}

export function getSalesMonthly() {
  return {
    type: GET_SALES_MONTHLY,
    payload: {
      request: {
        method: "GET",
        url:
          "https://n5t4bjbf28.execute-api.ap-southeast-1.amazonaws.com/Prod/sales-monthly",
      },
    },
  };
}

export function getSales() {
  return {
    type: GET_SALES,
    payload: {
      request: {
        method: "GET",
        url:
          "https://n5t4bjbf28.execute-api.ap-southeast-1.amazonaws.com/Prod/sales",
      },
    },
  };
}

export function getSalesByPIC(id) {
  return {
    type: GET_SALES_BY_PIC,
    payload: {
      request: {
        method: "GET",
        url:
          "https://n5t4bjbf28.execute-api.ap-southeast-1.amazonaws.com/Prod/sales?pic=" +
          id,
      },
    },
  };
}

export function createSales(data) {
  return {
    type: CREATE_SALES,
    payload: {
      request: {
        method: "POST",
        url:
          "https://n5t4bjbf28.execute-api.ap-southeast-1.amazonaws.com/Prod/sales",
        headers: {},
        data: data,
      },
    },
  };
}

export function updateSales(data) {
  return {
    type: UPDATE_SALES,
    payload: {
      request: {
        method: "PUT",
        url:
          "https://n5t4bjbf28.execute-api.ap-southeast-1.amazonaws.com/Prod/sales-update/" +
          data.id,
        data: data,
      },
    },
  };
}

export function deleteSales(data) {
  return {
    type: DELETE_SALES,
    payload: {
      request: {
        method: "DELETE",
        url:
          "https://n5t4bjbf28.execute-api.ap-southeast-1.amazonaws.com/Prod/sales-delete/" +
          data.id,
      },
    },
  };
}

export function detailSales(data) {
  return {
    type: DETAIL_SALES,
    payload: {
      request: {
        url:
          "https://n5t4bjbf28.execute-api.ap-southeast-1.amazonaws.com/Prod/sales-detail/" +
          data.id,
        method: "GET",
      },
    },
  };
}
