// Actions
const GET_ALL_AREA = "area/GET-ALL-AREA";
const GET_ALL_AREA_SUCCESS = "area/GET-ALL-AREA_SUCCESS";
const GET_ALL_AREA_FAIL = "area/GET-ALL-AREA_FAIL";

const GET_ALL_AREA_BY_PIC = "area/GET-ALL-AREA-BY-PIC";
const GET_ALL_AREA_BY_PIC_SUCCESS = "area/GET-ALL-AREA-BY-PIC_SUCCESS";
const GET_ALL_AREA_BY_PIC_FAIL = "area/GET-ALL-AREA-BY-PIC_FAIL";

const CREATE_AREA = "area/CREATE-AREA";
const CREATE_AREA_SUCCESS = "area/CREATE-AREA_SUCCESS";
const CREATE_AREA_FAIL = "area/CREATE-AREA_FAIL";

const DETAIL_AREA = "area/DETAIL-AREA";
const DETAIL_AREA_SUCCESS = "area/DETAIL-AREA_SUCCESS";
const DETAIL_AREA_FAIL = "area/DETAIL-AREA_FAIL";

const DELETE_AREA = "area/DELETE-AREA";
const DELETE_AREA_SUCCESS = "area/DELETE-AREA_SUCCESS";
const DELETE_AREA_FAIL = "area/DELETE-AREA_FAIL";

const initialState = {
  getAreaData: [],
  getAreaByPicData: [],
  getAreaStatus: false,
  getAreaByPicStatus: false,
  createAreaStatus: false,
  createAreaMessage: "",
  deleteAreaStatus: false,
  deleteAreaMessage: "",
  detailAreaData: {},
  detailAreaStatus: false,
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_ALL_AREA:
      console.log(action);
      return {
        ...state,
        getAreaData: [],
        getAreaStatus: false,
      };
    case GET_ALL_AREA_SUCCESS:
      console.log(action.payload.data);
      return {
        ...state,
        getAreaData: action.payload.status === 200 ? action.payload.data : [],
        getAreaStatus: true,
      };
    case GET_ALL_AREA_FAIL:
      return state;

    case GET_ALL_AREA_BY_PIC:
      return {
        ...state,
        getAreaByPicData: [],
        getAreaByPicStatus: false,
      };
    case GET_ALL_AREA_BY_PIC_SUCCESS:
      return {
        ...state,
        getAreaByPicData:
          action.payload.status === 200 ? action.payload.data : [],
        getAreaByPicStatus: true,
      };
    case GET_ALL_AREA_BY_PIC_FAIL:
      return state;

    ////
    case CREATE_AREA:
      return {
        ...state,
        createAreaStatus: false,
        createAreaMessage: "",
      };
    case CREATE_AREA_SUCCESS:
      return {
        ...state,
        createAreaStatus: true,
        createAreaMessage: action.payload.status === 200 ? "SUCCESS" : "",
      };
    case CREATE_AREA_FAIL:
      return {
        ...state,
        createAreaMessage: action.error.response.status === 500 ? "FAIL" : "",
      };

    ////
    case DETAIL_AREA:
      return {
        ...state,
        detailAreaData: {},
        detailAreaStatus: false,
      };
    case DETAIL_AREA_SUCCESS:
      return {
        ...state,
        detailAreaData:
          action.payload.status === 200 ? action.payload.data : {},
        detailAreaStatus: true,
      };
    case DETAIL_AREA_FAIL:
      return {
        ...state,
      };

    ////
    case DELETE_AREA:
      return {
        ...state,
        deleteAreaStatus: false,
        deleteAreaMessage: "",
      };
    case DELETE_AREA_SUCCESS:
      return {
        ...state,
        deleteAreaStatus: true,
        deleteAreaMessage: action.payload.status === 200 ? "SUCCESS" : "",
      };
    case DELETE_AREA_FAIL:
      return {
        ...state,
      };
    default:
      return state;
  }
}

// Action Creators
export function getAllArea() {
  return {
    type: GET_ALL_AREA,
    payload: {
      request: {
        method: "GET",
        url:
          "https://u55uk0x1d6.execute-api.ap-southeast-1.amazonaws.com/Prod/area",
      },
    },
  };
}

export function getAreaByPIC(pic) {
  return {
    type: GET_ALL_AREA_BY_PIC,
    payload: {
      request: {
        method: "GET",
        url:
          "https://u55uk0x1d6.execute-api.ap-southeast-1.amazonaws.com/Prod/area-by-pic?pic=" +
          pic,
      },
    },
  };
}

export function createArea(data) {
  return {
    type: CREATE_AREA,
    payload: {
      request: {
        url:
          "https://m51djyomjb.execute-api.ap-southeast-1.amazonaws.com/dev/add-area",
        method: "POST",
        data: data,
      },
    },
  };
}

export function detailArea(data) {
  return {
    type: DETAIL_AREA,
    payload: {
      request: {
        method: "GET",
        url:
          "https://u55uk0x1d6.execute-api.ap-southeast-1.amazonaws.com/Prod/area-detail/" +
          data.id,
      },
    },
  };
}

export function deleteArea(data) {
  return {
    type: DELETE_AREA,
    payload: {
      request: {
        method: "DELETE",
        url:
          "https://u55uk0x1d6.execute-api.ap-southeast-1.amazonaws.com/Prod/area-delete/" +
          data.id,
      },
    },
  };
}
